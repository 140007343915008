import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import LightweightFile from '../pages/file/lightweight';

const FileSelect = ({
  value,
  setValue,
  handleCallback,
  filesId,
  defaultValue,
  autoOpen = false,
  audio = false,
  img = true,
  type = 'project',
}) => {
  const [show, setShow] = useState(autoOpen);

  const handleHide = () => {
    setShow(false);
  };

  const displayValue = value && value?.name;
  let fileType = 'Asset';
  if (audio) {
    fileType = 'Audio';
  } else if (img) {
    fileType = 'Image';
  } else fileType = 'Asset';

  return (
    <>
      <span role="button" tabIndex={0} onClick={() => setShow(true)}>
        {value?.url && img && !audio && (
          <img
            alt="current"
            style={{height: '30px', width: '30px'}}
            className="mr-4"
            src={value?.url}
          />
        )}
        {displayValue || `No ${fileType} selected, click to open file browser`}
      </span>

      <Modal show={show} onHide={handleHide}>
        <Modal.Header style={{justifyContent: 'space-between'}}>
          <Modal.Title>Select {fileType}</Modal.Title>
          <button
            onClick={handleHide}
            type="button"
            style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <LightweightFile
            type={type}
            id={filesId}
            compact
            audio={audio}
            inner
            onFileClick={e => {
              console.log('selected:', e);
              const data = {
                name: e.displayName,
                url: `${process.env.REACT_APP_S3_URL}/${e.versions[e.versions.length - 1].name}`,
              };
              setValue(data);
              setShow(false);
              handleCallback(e);
            }}
          />
        </Modal.Body>
        {(defaultValue || !img) && (
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                const data = defaultValue
                  ? {
                      name: `Default ${fileType}`,
                      url: defaultValue,
                    }
                  : {};
                setValue(data);
                setShow(false);
              }}
            >
              Use default {fileType}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default FileSelect;
