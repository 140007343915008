/* eslint-disable react/jsx-props-no-spreading */
import React, {useMemo, useState} from 'react';
import {Button} from 'react-bootstrap';
import CreativeExportModal from './CreativeExportModal';
import DcoPreviewExportModal from './DcoPreviewExportModal';

const CreativeExport = props => {
  const {creative, version} = props;
  const [show, setShow] = useState(false);

  const includesGrid8Player = useMemo(
    () =>
      creative?.versions?.[version]?.files?.includes('/grid8player.js') ||
      creative?.versions?.[version]?.files?.includes('/grid8player.min.js'),
    [creative?.versions, version]
  );

  const onClose = () => {
    setShow(false);
  };
  const onShow = () => {
    setShow(true);
  };
  return includesGrid8Player ? (
    <>
      {!props.alternative ? (
        <svg
          type="button"
          onClick={onShow}
          xmlns="http://www.w3.org/2000/svg"
          width="164"
          style={{
            position: 'relative',
            bottom: '21px',
          }}
          height="82"
          viewBox="0 0 164 82"
        >
          <defs>
            <filter
              id="Rectangle_3535"
              x="0"
              y="0"
              width="164"
              height="82"
              filterUnits="userSpaceOnUse"
            >
              <feOffset in="SourceAlpha" />
              <feGaussianBlur stdDeviation="7" result="blur" />
              <feFlood floodOpacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g id="Component_159_16" data-name="Component 159 – 16" transform="translate(21 21)">
            <g transform="matrix(1, 0, 0, 1, -21, -21)" filter="url(#Rectangle_3535)">
              <rect
                id="Rectangle_3535-2"
                data-name="Rectangle 3535"
                width="122"
                height="40"
                rx="20"
                transform="translate(21 21)"
                fill="#081ee0"
              />
            </g>
            <path
              id="Path_4788"
              data-name="Path 4788"
              d="M-51.936-1.548v-1.92h3.36V-5.016h-3.36V-6.792h3.8V-8.34h-5.592V0h5.664V-1.548ZM-41.208-8.34H-44.46V0h3.252A3.987,3.987,0,0,0-36.96-4.176,3.978,3.978,0,0,0-41.208-8.34Zm-.06,6.756h-1.4V-6.756h1.4a2.368,2.368,0,0,1,2.5,2.6A2.351,2.351,0,0,1-41.268-1.584ZM-33.372,0h1.788V-8.34h-1.788Zm11.82-8.34H-28.26v1.584h2.46V0h1.788V-6.756h2.46Zm6.888,8.5a4.263,4.263,0,0,0,4.344-4.32A4.266,4.266,0,0,0-14.664-8.5,4.276,4.276,0,0,0-19.02-4.164,4.273,4.273,0,0,0-14.664.156Zm0-1.692a2.477,2.477,0,0,1-2.544-2.64A2.467,2.467,0,0,1-14.664-6.8a2.457,2.457,0,0,1,2.532,2.628A2.468,2.468,0,0,1-14.664-1.536ZM-2.256,0h1.98L-2.76-3.456A2.458,2.458,0,0,0-.42-5.88c0-1.428-1.128-2.46-2.916-2.46H-6.744V0H-4.98V-3.42h.348ZM-4.98-6.78h1.6c.78,0,1.224.408,1.224,1.056S-2.6-4.68-3.384-4.68h-1.6Z"
              transform="translate(97 29)"
              fill="#fff"
            />
            <path
              id="Path_4789"
              data-name="Path 4789"
              d="M-32.452.091a2.487,2.487,0,0,0,2.534-2.52,2.488,2.488,0,0,0-2.534-2.527,2.494,2.494,0,0,0-2.541,2.527A2.493,2.493,0,0,0-32.452.091Zm0-.987a1.445,1.445,0,0,1-1.484-1.54,1.439,1.439,0,0,1,1.484-1.533,1.433,1.433,0,0,1,1.477,1.533A1.439,1.439,0,0,1-32.452-.9Zm6.608-3.969h-1.988V0h1.043V-1.8h.945a1.523,1.523,0,0,0,1.7-1.533A1.516,1.516,0,0,0-25.844-4.865ZM-25.9-2.709h-.889V-3.955h.889a.612.612,0,0,1,.7.623A.612.612,0,0,1-25.9-2.709ZM-21.112-.9v-1.12h1.96v-.9h-1.96V-3.962h2.219v-.9h-3.262V0h3.3V-.9Zm7.273-3.962v3.094L-15.89-4.865h-.861V0h1.036V-3.073L-13.671,0h.868V-4.865ZM-7.735,0h1.043V-4.865H-7.735Zm6.251-4.865v3.094L-3.535-4.865H-4.4V0H-3.36V-3.073L-1.316,0h.868V-4.865Z"
              transform="translate(78 17)"
              fill="#fff"
            />
            <circle
              id="Ellipse_495"
              data-name="Ellipse 495"
              cx="8.5"
              cy="8.5"
              r="8.5"
              transform="translate(13 12)"
              fill="#ee5253"
            />
          </g>
        </svg>
      ) : (
        <div role="button" onClick={onShow} className="icon icon-export-dco-creative " />
      )}
      {props.q2a ? (
        show && <DcoPreviewExportModal show={show} onClose={onClose} {...props} />
      ) : (
        <CreativeExportModal show={show} onClose={onClose} {...props} />
      )}
    </>
  ) : null;
};

export default CreativeExport;
