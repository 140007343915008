/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TooltipHelper from '../../../../../../../helpers/tooltip';
import {stateColorCoding} from '../../../../consts';
import UserCellRenderer from './UserCellRenderer';

const TooltipData = ({data}) => {
  return (
    <div className="d-flex flex-column align-items-start">
      <p className="mb-1">
        Last Changed by:
        <span className="primary-text ml-2">
          <UserCellRenderer value={data?.published?.lastEditBy} />
        </span>
      </p>

      <span>Last Changed Date:</span>
      <p className="primary-text mb-1">{data?.published?.lastChange}</p>
      <span>Last Published Date: </span>
      <p className="primary-text mb-1">{data?.published?.lastPublish}</p>
    </div>
  );
};

const StateRenderer = props => {
  const {value, colDef} = props;

  return (
    <TooltipHelper content={<TooltipData {...props} />}>
      <div className="d-flex justify-content-between align-items-center">
        <span className={`font-weight-bold ${stateColorCoding[value]}`}>
          {value && <span>{`\u2022`} </span>}
          {value}
        </span>
        {colDef.field === 'State' && (
          <span
            className="icon icon-arrow-left dco-filters-header-icon x10 primary-background mr-2"
            style={{transform: 'rotate(270deg)', width: '14px', height: '14px'}}
          />
        )}
      </div>
    </TooltipHelper>
  );
};

export default StateRenderer;
