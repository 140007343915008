import React, {useState, useMemo, forwardRef, useImperativeHandle} from 'react';
import queryString from 'query-string';
import {useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import _ from 'lodash';

import {Filters} from './components/Filters';
import {Preview} from './components/Previews';
import Pagination from '../../../../helpers/pagination';
import RouteGuard from '../../../../helpers/RouteGuard';
import {stateColorCoding} from '../consts';
import Access from '../../../../helpers/access';

const Overview = ({ad: ads, setup, parents, assets, setTab, currentAd, setCurrentAd}, ref) => {
  const {items: data} = ads;

  const location = useLocation();

  const [filters, setFilters] = useState({
    [`filter${0}`]: {field: 'Search', value: null, label: 'Search'},
    [`filter${1}`]: {field: 'sizes', value: setup.sizes, label: 'Sizes'},
    [`filter${2}`]: {field: 'Published State', value: null, label: 'Published State'},
  });
  const [adToEdit, setAdToEdit] = useState(null);
  const [filter, setFilter] = useState({
    page: parseInt(queryString.parse(location.search).page, 10)
      ? parseInt(queryString.parse(location.search).page, 10)
      : 1,
    limit: 1,
  });

  const sizeFilter = useMemo(() => {
    return Object.values(filters).find(filt => filt.field === 'sizes');
  }, [filters]);

  const currentAdChanged = useMemo(() => {
    const oldAd = ads.items.find(item => item._id === currentAd?._id);
    if (!oldAd) {
      return false;
    }

    return !_.isEqual(
      {
        ...currentAd,
        published: {},
      },
      {...oldAd, published: {}}
    );
  }, [ads.items, currentAd]);

  const filteredData = useMemo(() => {
    return data.filter(item => {
      let result = true;
      Object.values(filters).forEach(filt => {
        if (!result) {
          return;
        }

        const {field, value} = filt;

        if (!field || !value) {
          return;
        }

        if (field === 'Published State') {
          if (value === 'Never Published') {
            result = item.published?.PublishedState === '';
          } else {
            result = item?.published?.PublishedState === value;
          }
          if (result) {
            return;
          }
        }

        if (field === 'Search') {
          result = Object.values(item.columns).some(val => {
            return String(val)
              ?.toLowerCase()
              .includes(String(value)?.toLowerCase());
          });
          if (result) {
            return;
          }

          item.shots.forEach(shot => {
            shot.shotSizes.forEach(shotItem => {
              if (result) {
                return;
              }

              result = Object.values(shotItem).some(val =>
                String(val)
                  ?.toLowerCase()
                  .includes(String(value)?.toLowerCase())
              );
            });
          });
          return;
        }

        if (field === 'sizes') {
          result = true;
          return;
        }

        if (field in item.columns) {
          result = String(item.columns[field])
            ?.toLowerCase()
            .includes(String(value)?.toLowerCase());
          return;
        }
        item.shots.forEach(shot => {
          result = shot.shotSizes.some(shotItem => {
            if (field in shotItem) {
              return String(shotItem[field])
                ?.toLowerCase()
                .includes(String(value)?.toLowerCase());
            }
            return false;
          });
        });
      });

      return result;
    });
  }, [data, filters]);

  useImperativeHandle(ref, () => ({
    getData: () => ({filteredData, filters}),
  }));

  const applyFilter = params => {
    setFilter({...filter, ...params});
  };

  const handleEdit = adValue => {
    setCurrentAd({...adValue});
    setTab('edit');
  };

  const handleCancelClicked = () => {
    setAdToEdit(null);
    return true;
  };

  const handleContinueClicked = () => {
    handleEdit(adToEdit);
    handleCancelClicked();
  };

  const handleEditClicked = adValue => {
    if (currentAdChanged) {
      setAdToEdit(adValue);
      return;
    }
    handleEdit(adValue);
  };

  return (
    <>
      <div className="dco-filter-area">
        <Filters
          setup={setup}
          filters={filters}
          projectId={parents.projectId}
          setFilters={setFilters}
          assets={assets}
        />
      </div>
      <div className="d-flex mb-2 ">
        <Pagination
          applyFilter={applyFilter}
          total={filteredData.length}
          localName="dcoOverviewLimit"
          filter={filter}
          options={[1, 5, 10, 20, 50, 100]}
          className="dco-pagination"
        />
      </div>
      {filteredData
        .slice((filter.page - 1) * Number(filter.limit), filter.page * Number(filter.limit))
        .map(ad => {
          return (
            <div className="ad-preview-wrapper" key={ad._id}>
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="font-weight-semibold">{ad.columns['Ad Name'] || ''} </h3>
                <div>
                  <span className={`font-weight-bold ${stateColorCoding[ad?.columns?.State]} mr-4`}>
                    <span>{`\u2022`} </span>
                    {ad?.columns?.State}
                  </span>

                  <Access type="dco" action="edit" parents={parents}>
                    {() => (
                      <Button
                        type="button"
                        onClick={() => {
                          handleEditClicked(ad);
                        }}
                        className="mr-4 dco-button primary "
                      >
                        Edit Ad
                      </Button>
                    )}
                  </Access>
                </div>
              </div>
              <div className="preview-wrapper">
                <Preview
                  setup={setup}
                  adColumns={ad.columns}
                  shots={ad.shots}
                  adSizes={ad.sizes}
                  assets={assets}
                  filterSizes={sizeFilter?.value}
                  overview
                  parents={parents}
                />
              </div>
            </div>
          );
        })}

      <RouteGuard
        manualShow={!!adToEdit}
        title="Unsaved Changes"
        message="You already have an ad in Editing. Do you want to discard the changes??"
        cancelText="Cancel"
        cancelColor="disabled"
        continueText="Discard"
        continueColor="danger"
        onManualClose={handleCancelClicked}
        onContinue={handleContinueClicked}
        onCancel={handleCancelClicked}
      />
    </>
  );
};

export default connect(
  store => ({
    ad: store.ad,
    project: store.project,
  }),
  null,
  null,
  {forwardRef: true}
)(forwardRef(Overview));
